import React, { Component } from "react";

class Portfolio extends Component {
  render() {
    if (this.props.data) {
      var projects = this.props.data.projects.map(function (projects) {
        var projectImage = "images/portfolio/" + projects.image;
        return (
          <div key={projects.title} className="columns portfolio-item">
            <div className="item-wrap">
              {/* <a href={projects.url} title={projects.title}> */}
              <img
                alt={projects.title}
                src={projectImage}
                className="project__img"
              />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5>{projects.title}</h5>
                  <p>{projects.category}</p>
                  <br />
                  <p>{projects.description}</p>
                  <br />
                  <p>{projects.tech}</p>
                </div>
              </div>
            </div>
            <div className="project__icons">
              <a href={projects.url} target="_blank" rel="noopener noreferrer">
                <i
                  className="fa fa-link"
                  style={{
                    fontSize: "25px",
                    paddingRight: "15px",
                    color: "#313131",
                  }}
                ></i>
              </a>
              <a
                href={projects.gh_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className={projects.gh_icon}
                  style={{ fontSize: "25px", color: "#313131" }}
                ></i>
              </a>
            </div>
          </div>
        );
      });
    }

    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Check Out Some of My Works.</h1>

            <div
              id="portfolio-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              {projects}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
