import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const Contact = ({ data }) => {
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const USER_ID = process.env.REACT_APP_USER_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const linkedin_url = data?.social[0].url;
  const gh_url = data?.social[1].url;
  const download_resume = "images/pdf/" + data?.resumedownload;

  const reset = () => {
    setName("");
    setSubject("");
    setEmail("");
    setMessage("");
  };
  const createNotification = (type) => {
    return () => {
      switch (type) {
        case "info":
          NotificationManager.info("Info message");
          break;
        case "success":
          NotificationManager.success(
            "I will respond as soon as I can, Thank you!",
            "Email Sent"
          );
          break;
        case "warning":
          NotificationManager.warning(
            "Warning message",
            "Close after 3000ms",
            3000
          );
          break;
        case "error":
          NotificationManager.error(
            "Please resend your message!",
            "Oh no! There seems to be an error ",
            5000,
            () => {
              alert("callback");
            }
          );
          break;
        default: // not needed
      }
    };
  };
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        createNotification("success");
      },
      (error) => {
        createNotification("error");
        console.log(error.text);
      }
    );

    reset();
  }

  return (
    <section id="contact">
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span>Get In Touch.</span>
          </h1>
        </div>

        <div className="ten columns">
          <p className="lead">{data?.message}</p>
        </div>
      </div>

      <div className="row">
        <div className="eight columns">
          <form id="contactForm" name="contactForm" onSubmit={sendEmail}>
            <fieldset>
              <div>
                <label htmlFor="contactName">
                  Name <span className="required">*</span>
                </label>
                <input
                  value={name}
                  type="text"
                  size="35"
                  id="contactName"
                  name="contactName"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactEmail">
                  Email <span className="required">*</span>
                </label>
                <input
                  value={email}
                  type="text"
                  size="35"
                  id="contactEmail"
                  name="contactEmail"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactSubject">Subject</label>
                <input
                  value={subject}
                  type="text"
                  size="35"
                  id="contactSubject"
                  name="contactSubject"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactMessage">
                  Message <span className="required">*</span>
                </label>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  cols="50"
                  rows="15"
                  id="contactMessage"
                  name="contactMessage"
                ></textarea>
              </div>
              <NotificationContainer />
              <div>
                <button
                  type="submit"
                  onClick={createNotification("success")}
                  className="submit"
                >
                  Submit
                </button>
                <span id="image-loader">
                  <img alt="" src="images/loader.gif" />
                </span>
              </div>
            </fieldset>
          </form>
        </div>

        <aside className="four columns footer-widgets">
          <div className="widget widget_contact">
            <h4>Contact Info</h4>
            <p className="address">
              {data?.name}
              <br />
              {data?.email}
              <br />
              {data?.address.city}, {data?.address.state}
              <br />
              <span>{data?.phone}</span>
            </p>
            <div>
              <a href={linkedin_url} target="_blank" rel="noopener noreferrer">
                <i
                  className={data?.social[0].className}
                  style={{
                    fontSize: "25px",
                    paddingRight: "15px",
                    color: "#313131",
                  }}
                ></i>
              </a>
              <a href={gh_url} target="_blank" rel="noopener noreferrer">
                <i
                  className={data?.social[1].className}
                  style={{
                    fontSize: "25px",
                    paddingRight: "15px",
                    color: "#313131",
                  }}
                ></i>
              </a>
              <a href={download_resume} download="Benjamin_Varnum_Resume">
                <i
                  className="fa fa-download"
                  style={{
                    fontSize: "25px",
                    paddingRight: "15px",
                    color: "#313131",
                  }}
                ></i>
              </a>
            </div>
          </div>
        </aside>
      </div>
    </section>
  );
};

export default Contact;
